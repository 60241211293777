import React from "react";
import "./progress.css";

const Progress = () => {
  return (
    <div className="progress">
      <div className="wind-up">
      </div>
    </div>
  );
};

export default Progress;
