import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import SchoolIcon from "@mui/icons-material/School";
import HandshakeIcon from "@mui/icons-material/Handshake";

import javascript from "programming-languages-logos/src/javascript/javascript.svg";
import java from "programming-languages-logos/src/java/java.png";
import python from "programming-languages-logos/src/python/python.png";
import cpp from "programming-languages-logos/src/cpp/cpp.png";
import haskell from "programming-languages-logos/src/haskell/haskell.png";
import html from "programming-languages-logos/src/html/html.png";
import css from "programming-languages-logos/src/css/css.png";

export async function fetchSectionList(setSections, setLoading, setSelected) {
  try {
    const result = await fetch(process.env.REACT_APP_API + "/portfolio/get");
    const sections = await result.json();
    setLoading(false);
    setSections(sections);
    setSelected(sections[0]);
  } catch (error) {
    // console.error(error);
  }
}

export async function fetchProjectsBySection(section, setData, setLoading) {
  try {
    const result = await fetch(
      process.env.REACT_APP_API + "/portfolio/get?section=" + section
    );
    const projects = await result.json();
    setData(projects);
    setLoading(false);
  } catch (error) {
    // console.error(error);
  }
}

export async function sendContactRequest(email, message) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, message }),
  };
  console.log(requestOptions);
  fetch(process.env.REACT_APP_API + "/contact/add", requestOptions)
    .then((response) => response.json())
    .then((data) => console.log(data));
}

export const siteModes = [
  "Home",
  "Programming",
  "Negotiation",
  "Entrepreneurship",
  "Public speaking",
  "Sports",
  "Barbershop",
];
export const siteModesRaw = [
  "",
  "programming",
  "negotiation",
  "entrepreneurship",
  "public%20speaking",
  "sports",
  "barbershop",
];

export const projectsList = [
  {
    id: 0,
    sectionTitle: "featured",
    items: [
      {
        id: 1,
        title: "Ecommerce app",
        img: "http://mateibucur.me/images/baby-things.png",
      },
      {
        id: 2,
        title: "Book list app",
        img: "http://mateibucur.me/images/book-list.PNG",
      },
    ],
  },
  {
    id: 1,
    sectionTitle: "featured",
    items: [
      {
        id: 1,
        title: "Ecommerce app",
        img: "http://mateibucur.me/images/baby-things.png",
      },
    ],
  },
  {
    id: 2,
    sectionTitle: "featured",
    items: [
      {
        id: 2,
        title: "Book list app",
        img: "http://mateibucur.me/images/book-list.PNG",
      },
    ],
  },
];

export const sectionsList = ["Featured", "Featured2", "Featured3"];

export const worksList = [
  {
    id: 1,
    title: "Multicultural",
    desc: "Having lived on two continents allows me to see the world from different perspectives. It has taught me to be more open-minded and how to adapt successfully to any environment.",
    img: "./assets/map.png",
  },
  {
    id: 2,
    title: "Adventurous",
    desc: "I like to try new things and I am always up for a challenge. I feel alive when I am doing something new and challenging. I like to push myself outside of my comfort zone and see what I am capable of.",
    img: "./assets/twente.png",
  },
  {
    id: 3,
    title: "Explorer",
    desc: "For me, it is the lure of adventure and the unknown that is most appealing. I always wanted to find out what else is out there, beyond my everyday experiences.",
    img: "./assets/postech.png",
  },
];

export const testimonialsList = [
  {
    id: 1,
    name: "Gil Bates",
    title: "CEO",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK9gqFKRn28xKHD1CAbEevdzsLmsv5yQkGnQ&usqp=CAU",
    icon: "assets/writing.png",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. id reiciendis vero at temporibus accusantium non veniam sequi.",
    featured: true,
  },
  {
    id: 1,
    name: "Melon Husk",
    title: "CEO",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Elon_Musk_Royal_Society_%28crop2%29.jpg/330px-Elon_Musk_Royal_Society_%28crop2%29.jpg",
    icon: "assets/globe.png",
    desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. id reiciendis vero at temporibus accusantium non veniam sequi.",
    featured: false,
  },
];

export const certifications = [
  {
    header: "Responsive Web Design",
    body: `Learnt the languages that developers use to build webpages: HTML (Hypertext Markup Language) for content, and CSS (Cascading Style Sheets) for design. Learnt how to make webpages that respond to different screen sizes.`,
    img: "fcc.png",
  },
  {
    header: "JavaScript Algorithms and Data Structures",
    body: `Learnt the fundamentals of JavaScript including variables, arrays, objects, loops, and functions. Applied that knowledge by creating algorithms to manipulate strings and factorialize numbers. Revised two important programming styles or paradigms: Object Oriented Programming (OOP) and Functional Programming (FP)
`,
    img: "fcc.png",
  },
  {
    header: "Front End Development Libraries",
    body: `Learnt how to style quickly with Bootstrap. Learn how add logic to CSS styles and extend them with Sass. Learnt jQuery, React and Redux. Implemented five web applications using React.
`,
    img: "fcc.png",
  },
];

//NEGOTIATION

export const negSkills = [
  {
    header: "Win-win mindset",
    img: "winwin.png",
    body: "Entering into negotiations with the goal of finding a solution that is mutually beneficial for both parties involved",
  },
  {
    header: "Active listening",
    img: "listen.png",
    body: "Paying close attention to the other party in order to fully understand their position, needs and underlying interests. This is achieved using techniques like mirroring, the use of calibrated questions and minimal encouragements.",
  },
  {
    header: "Negotiation intelligence",
    img: "intelligence.png",
    body: "Clear understanding of myself and my partner’s interests and options. Setting up a clear strategy and plan for achieving my negotiation objectives. Knowledge of negotiation jargon and techniques. ",
  },
  {
    header: "Self assessment",
    img: "reflection.png",
    body: "Reflecting allows me to see where I need to improve and also to be more mindful of my own actions and how they might affect the negotiation process.",
  },
];
export const negExperience = [
  {
    id: 1,
    title: "NPT Graduate",
    desc: "Took part in a 8 month course at Negotiation Project Twente. Built a broad understanding of the negotiation process by attending all the theoretical sessions, reading recommended books and participating in countless case simulations.",
    img: "assets/npt_grad.jpeg",
  },
  {
    id: 2,
    title: "Wnr Finalist",
    desc: "I took part in one of the largest negotiation competition for students. My team won The most Cooperative Team award and got the 4th place.",
    img: "assets/wnr.png",
  },
  {
    id: 3,
    title: "Learning from failure",
    desc: "The second round of WNR was a disaster, we felt we lost everything. In less than 24 hours we managed to comeback, ranking 1st in the last two rounds. I am grateful we did not win, I have learnt so much from this. Never give up!",
    img: "assets/failure.png",
  },
  {
    id: 4,
    title: "Team worker",
    desc: "Working with Nayim and Mart was one of the best experiences in my negotiation journey. We learnt how to complement each other's strengths and be effective as a team",
    img: "assets/wnrTeam.JPG",
  },
];

//ENTREPRENEURSHIP
export const babythings = {
  name: "Babythings",
  lifetime: "Sep 2021 - Jul 2022",
  img: "business_team.png",
  header:
    "As a CTO and co-founder, I had to assist the team in business development and market research while developing the website of the store",
  tasks: [
    "Conducted field interviews with potential customers",
    "Created multiple MVP websites",
    "Designed, developed, deployed, and maintained all software ",
    "Developed the e-commerce platform using React.js and Commerce.js",
    "Worked in a team of five people of different nationalities and backgrounds",
    "Developed the business plan and pitched the business idea as the final graduation assignment of the Honours Business & Entrepreneurship program",
  ],
};
export const businesses = [
  {
    header: "BabyThings",
    img: "business.PNG",
    body: "Through a monthly subscription service KiekeBox provides high quality, European-based, gender neutral baby products sourced from small-scale, conscious brands to conscious parents of 0-24 month old babies.",
  },
  {
    header: "Twente Hackathon",
    img: "hackathon.PNG",
    body: "Our goal was to engage ingenious students from University of Twente and Saxion and present them with real life software problems of low or medium complexity selected by our partner companies so they can deliver a creative solution. ",
  },
  {
    header: "Fat Rhino studios",
    img: "rhino.gif",
    body: "My first business was creating quiz apps, posting them on Play store and displaying ads. I created a quiz apps that allowed people to test their knowledge on a variety of topics.",
  },
];

//SPORTS
export const sports = [
  {
    header: "Running",
    descr:
      "I run because it makes me feel alive. The fresh air, the endorphins, the feeling of my heart pumping and my lungs working--it's all invigorating and empowering. I also run because it requires mental toughness. Every time I lace up my shoes and head out the door, I know I'm going to have to push myself physically and mentally. I might get tired, I might want to give up, but I never do. Why? Because I know that running makes me stronger, both physically and mentally. It makes me better able to handle whatever life throws my way.",
    sideHeader: "Samcheok Marathon, South Korea",
    sideDescr: "Watch my finish, in 4 hours and 45 minutes",
    iframe: "https://www.youtube.com/embed/d6ywzwNWXmE?start=15083",
  },
  {
    header: "Working out",
    descr:
      "There are a few reasons why I workout everyday. First, it Discipline. I have to be disciplined to show up everyday and put in the work even when I do not feel like doing it. Second, it keeps me mentally tough. I have to be mentally tough to push myself everyday and stay consistent.",
    sideHeader: "Ulympics sports competition, Enschede",
    sideDescr: "3rd place at pull-ups, dips and 2nd at arm wrestling",
    img: "assets/pullups.JPG",
  },
];

export const person = {
  name: "Matei Bucur",
  status: "Student",
  attributes: ["Web Developer", "Entrepreneur", "Negotiator"],
  linkedIn: "https://www.linkedin.com/in/matei-bucur-57a591207/",
  githubLink: "https://github.com/mateib47",
  wordCloud: [
    { text: "Negotiation", value: 190 },
    { text: "Programming", value: 190 },
    { text: "Entrepreneurship", value: 170 },
    { text: "Gym", value: 150 },
    { text: "Calisthenics", value: 90 },
    { text: "Running", value: 120 },
    { text: "Climbing", value: 90 },
    { text: "Self-development", value: 180 },
    { text: "Discipline", value: 200 },
    { text: "Ambition", value: 140 },
    { text: "Web-development", value: 180 },
    { text: "Critical thinking", value: 100 },
    { text: "Problem solving", value: 110 },
    { text: "Communication", value: 170 },
    { text: "Public speaking", value: 150 },
    { text: "Travel", value: 140 },
    { text: "Planning", value: 90 },
    { text: "Teamwork", value: 120 },
    { text: "Baking bread", value: 130 },
    { text: "Cutting hair", value: 120 },
    { text: "Cycling", value: 90 },
    { text: "Reading", value: 130 },
    { text: "Partying", value: 70 },
  ],
  description: [
    `Hi, I am Matei, and I am looking for my dreams in the most unthinkable places.`,
    `I left Romania, I threw myself into the unknown, and I joined the University of Twente. I took every
  opportunity that I had in front of my eyes, whether it was joining the honours programme, starting a
  business, competing in a negotiation competition or having a Ted talk. I learned to love working, and
  all of these became a pastime for me. I broadened my scope besides my major by learning to communicate
  and collaborate efficiently. `,
    `I started developing habits like programming for personal projects, reading, 
  working out, and taking a cold shower every day for almost two years.  Gradually, I felt I had become free
  and at peace with myself by learning the power of self-discipline, by moving little by little every day
  towards my goals.`,
    `I had to reinvent myself again, so I continued to move further away from everything I knew. I departed
  once more, this time to the east, on another continent. I am currently in South Korea for an exchange semester at Pohang 
  University of Science and Technology. I am exploring new ways of thinking and the fascinating culture of this country.`,
    `I am graduating in July 2023, and I feel ready to start working and possibly do a master's. I am eager to expand 
  my knowledge and to apply my skills. I am always committed to putting work and effort into everything that I do.`,
    `Special interest in software/web-development, negotiation, business & entrepreneurship.`,
  ],
  boldedWords: [
    "University of Twente",
    "self-discipline",
    "Pohang \n  University of Science and Technology",
    "graduating in July 2023",
    "broadened my scope",
  ],
};

export const heights = [
  {
    h: 140,
    logo: java,
    descr: "Used it a lot of diverse projects; OOP, Multithreading, Sockets",
  },
  { h: 90, logo: python, descr: "Know the basics but currently doing more" },
  { h: 60, logo: cpp, descr: "Used for 4 years in high school" },
  { h: 100, logo: haskell, descr: "I love this language" },
  { h: 70, logo: html, descr: "Not really a programming language but yeah" },
  {
    h: 160,
    logo: javascript,
    descr: "Used for one year every day in a lot of projects",
  },
  { h: 90, logo: css, descr: "Know how to center a div:)" },
  {
    h: 80,
    logo: "assets/languages/androidstudio.png",
    descr: "Did couple of projects",
  },
  {
    h: 70,
    logo: "assets/languages/antlr.png",
    descr: "Built my own programming language and compiler",
  },
  { h: 80, logo: "assets/languages/maven.png", descr: "Used it" },
  {
    h: 150,
    logo: "assets/languages/react.png",
    descr: "Used it a lot but still more to learn",
  },
  {
    h: 100,
    logo: "assets/languages/posgresql.png",
    descr: "Used relational databases a lot both in high-school and uni",
  },
  {
    h: 50,
    logo: "assets/languages/prolog.png",
    descr: "Used it in one project, not a big fan",
  },
  {
    h: 50,
    logo: "assets/languages/redux.png",
    descr: "Know its purpose and how to use it in theory",
  },
  {
    h: 70,
    logo: "assets/languages/rust.png",
    descr: "Know just basic principles",
  },
  {
    h: 90,
    logo: "assets/languages/springboot.png",
    descr: "My go-to framework for backend",
  },
  {
    h: 110,
    logo: "assets/languages/sass.png",
    descr: "Got used to this instead of css",
  },
  {
    h: 130,
    logo: "assets/languages/mui.png",
    descr: "My go-to library for UI, started to like it a lot",
  },
];

export const experience = [
  {
    title: "Pohang University of science and technology",
    description: "Courses in AI fields",
    date: "Sep 2022 - Dec 2022",
    color: "text.primary",
    iconComponent: <SchoolIcon />,
  },
  {
    title: "Clik",
    description: "Fullstack React Native developer",
    date: "Jul 2022 - Aug 2022",
    color: "text.primary",
    iconComponent: <DeveloperModeIcon />,
  },
  {
    title: "Negotiation Project Twente",
    description: "Participant & Representative",
    date: "Oct 2021 - May 2022",
    color: "text.primary",
    iconComponent: <HandshakeIcon />,
  },
  {
    title: "University of Twente",
    description:
      "Bsc Technical Computer Science & Honours in Business and Entrepreneurship",
    date: "2020 - 2023",
    color: "text.primary",
    iconComponent: <SchoolIcon />,
  },
  {
    title: "'Tudor Vianu' National collage of computer science",
    description: "Mathematics and intensive informatics",
    date: "2016 - 2020",
    color: "text.primary",
    iconComponent: <SchoolIcon />,
  },
];

export const runningData = {
  activities: [
    {
      id: "bd1f21e1-92ba-4913-b2c9-9b3ade273910",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1613374577048,
      end_epoch_ms: 1613376491999,
      last_modified: 1613376985829,
      active_duration_ms: 1583000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4325,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4.8700227483355265,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1290.9405146534143,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.07522545420587,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 427.173,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 48.93015216626965,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.417496939280338,
        },
        {
          metric: "rpe",
          summary: "max",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 2,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.running.manualentry",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Monday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "-1.4111111111111105",
        "com.nike.weather": "cloudy",
        location: "outdoors",
        terrain: "road",
      },
      change_tokens: [
        "791e9f22603f0bad5f8c24ac9d5a43e002172d3906779e8efc0edc2a5569e871:1613376985829",
        "e166af17c7bfc7c374c58513a4af0ba601110bfa3595c2f6567e565bb6d445d4:1613376743363",
        "bb73ab2daf3a0b5f0226a601cee8f90bb57e2be0f63f30f1d0f10febef4f04b8:1613376738855",
        "6685377f31b8c1fee2f70dbc5d65a7748f854b918f9e055dd5dc26ef3559d35d:1613376723869",
        "fd4db1a9959fb52830cd31104120f2d936f468c2fde7994b7a4a1ecfa7282725:1613376718870",
        "f8ba2c30b185229ea9446f433c28b0a0bc930d407bf64b8b331a7a1d13244cde:1613376691415",
        "e18edfa3d37f8f5497dd2deb4c310a10b56f316423effd85e4bd0c59265b82f3:1613376493250",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "rpe",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1613374881742,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1613375233483,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1613375531597,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1613375881718,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613374934161,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613374939462,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613375580624,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613375585366,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613375597199,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613375602725,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613375820579,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613375829536,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613375889712,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613375900106,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613376168441,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613376179309,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613376199521,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613376208561,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1613376215734,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1613375087400,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1613375619597,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1613376158137,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "32500b9c-f4a7-4730-b16e-76b22c0cd278",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1613546704727,
      end_epoch_ms: 1613548493828,
      last_modified: 1613548689046,
      active_duration_ms: 1738000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.727323786596785,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4825,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.863271364642364,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1479.07938859563,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.0576268683211385,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 505.238,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 51.061428835292176,
        },
        {
          metric: "rpe",
          summary: "max",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 8,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.running.manualentry",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Wednesday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "5.716666666666666",
        "com.nike.weather": "cloudy",
        location: "outdoors",
      },
      change_tokens: [
        "8681c1264995c6e3aa356a9406c46c2d9c153bcab2ea0ff21e964d97b12869c5:1613548689046",
        "23206c58604f3695250a0390c92a2a5eff62aeaecb8af405fbcd6825fbd989ba:1613548637049",
        "ee2f4656998b9d527dd0da3ffb68f93b4710f2cf826bef907e5e1418e4ba15af:1613548628352",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "rpe",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1613547003741,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1613547299059,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1613547600735,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1613547938231,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1613548247319,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1613547189607,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1613547664981,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1613548194557,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613547363627,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613547373586,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613547858376,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613547890926,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613548486077,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "e4c79eec-f241-4c1d-9d10-fe24afb93910",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1613823282544,
      end_epoch_ms: 1613826544469,
      last_modified: 1613826579073,
      active_duration_ms: 1829000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4.0197253164045215,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 10.8299349094162,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 115.633,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 7.911979846394902,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 330.1325158220372,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 869,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 7.583436910211422,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Saturday Afternoon Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "13.32222222222222",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "c2b40fc046d49a2558de1bffb69c2c15706cac4094dc7f58b739d983479338fb:1613826579073",
        "f737fe906a84b446f82b8f86332d26d20084c428dd062453ddabf0baf62cf5db:1613826546437",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613823422544,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613823446616,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613823469905,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613823518802,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613823596446,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "resume",
          timestamp: 1613824040393,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613824056309,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "resume",
          timestamp: 1613824063406,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613824126985,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "resume",
          timestamp: 1613824133130,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613824740776,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "resume",
          timestamp: 1613824743751,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613824878247,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1613824885068,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1613824963136,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "resume",
          timestamp: 1613824966521,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1613825654814,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "1",
          timestamp: 1613824104910,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1613824927749,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1613825269858,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1613825622539,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1613824787573,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1613825340527,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "ab356d5e-7335-41bf-b89a-952e95f1b6f1",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1613979672720,
      end_epoch_ms: 1613981651782,
      last_modified: 1613981814617,
      active_duration_ms: 1971000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 504.083,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.749551169610818,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 10.43559718489493,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.713489458729974,
        },
      ],
      sources: ["com.nike.running.android.fullpower"],
      tags: {
        "com.nike.name": "Monday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "8.177777777777777",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "6c4ab1e98aef4be93ebe99927d5dfcff0ef4d3b9fef6013fb49acce3596125cf:1613981814617",
        "6489ae6629f4a732aa30cee87d34596c5adf3aaef67ee0b2fe8042c4a234e9d6:1613981702783",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "latitude",
        "calories",
        "speed",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1613979991767,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1613980306459,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1613980736498,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1613981063387,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1613981423351,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1613980184357,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1613980806592,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1613981369762,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1613981644242,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "d6869f94-f83a-4f5b-a6b2-6278a6cf92f3",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1614240324300,
      end_epoch_ms: 1614242173841,
      last_modified: 1614242324641,
      active_duration_ms: 1795000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 503.962,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5030,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.726791427890434,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1527.877432642802,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.485486986298362,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.223983978352606,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 51.07111195463405,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Thursday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "9.966666666666665",
        "com.nike.weather": "sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "e774ccadc6a1028c56be08212040c598d8bd86e2db453fde3658e9eac9e3a187:1614242324641",
        "43492eb03ddb3c836c39437356801903d5690dffc0dc8973e1c539b614198d0d:1614242305435",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1614240643727,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1614240926181,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1614241285666,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1614241584238,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1614241925601,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1614240815490,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1614241341687,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1614241869468,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1614241090351,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1614241097179,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1614241099167,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1614241113078,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1614241543040,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1614241547792,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1614241689273,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1614241695782,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1614241833762,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1614241846279,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1614242164217,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "546d5157-0496-45be-8fff-6bfb48f601fc",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1614415823769,
      end_epoch_ms: 1614418286550,
      last_modified: 1614418423674,
      active_duration_ms: 2394000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 7.55510944564171,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1917.6848560799651,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.361066835551442,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 656.197,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.281194175554528,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 48.06227709473597,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 6682,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Saturday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "3.994444444444443",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "010bb90ff2b2385abd1983aba7d4f6ea00d5ffcc4f9e7d1495032871497999fc:1614418423674",
        "3301d76d9dc43eec7b785f4b71c568e35d3ff9695213bd6542b33cdd38d2ed1f:1614418382995",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1614416120848,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1614416410113,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1614416772425,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1614417088652,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1614417421584,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1614417760220,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "7",
          timestamp: 1614418097581,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1614416290282,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1614416842606,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1614417369989,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "4",
          timestamp: 1614417909097,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1614416601100,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1614416649264,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1614417650950,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1614417659912,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1614417806227,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1614417813230,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1614418281964,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "d73441a3-2c65-4b26-9681-343287f32876",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1615793989887,
      end_epoch_ms: 1615795482201,
      last_modified: 1615795506346,
      active_duration_ms: 1409000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 49.10493762506526,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.379552174358029,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1153.1476185619492,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4.4538302815751285,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 389.02,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 3902,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.272615220764157,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Monday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "5.188888888888891",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "5316da8340485c949599d65429d36842da4fefebf93cb3a0fa5a4f7f46be5092:1615795506346",
        "1a81f91849cabd3eb3e6cd79623ca8508f76b6828218d7415004e07476d4a125:1615795483503",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1615794305876,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1615794625055,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1615794931436,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1615795256508,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1615794362216,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1615794367781,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1615795395267,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1615795406363,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1615795416089,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1615794506909,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1615794998526,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "9dd55837-8efa-4327-aa6b-65762b4e51cf",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1615965344343,
      end_epoch_ms: 1615967107761,
      last_modified: 1615967274919,
      active_duration_ms: 1757000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4819,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.501765879615965,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1460.2051014980984,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 49.864716044328915,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.216590272136835,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 495.424,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.613500736245903,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Wednesday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "3.683333333333335",
        "com.nike.weather": "cloudy",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "8d1c47a41e6721af2ced772f1fd2285708ac2f80fdd0c0f673e3f794a8fe4f8e:1615967274919",
        "e5081ee8b7f9d56e650719982d24d9dc9c7d91ab2fbf21dcdb73226b0b85f56c:1615967222492",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1615965669300,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1615966018744,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1615966335926,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1615966636058,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1615966937049,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1615965894322,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1615966407080,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1615966887712,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1615966035972,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1615966040195,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1615967105991,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "5e74995c-a045-40df-8ed2-9c2748c61498",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1616053455899,
      end_epoch_ms: 1616057522939,
      last_modified: 1616058267892,
      active_duration_ms: 4015000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.700154759458641,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 10719,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 3100.244895458043,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.7192975684847145,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 10.49079878805756,
        },
        {
          metric: "rpe",
          summary: "max",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 8,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 1020.944,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 46.32993617122854,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.running.manualentry",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Thursday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "2.0888888888888877",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
        terrain: "road",
      },
      change_tokens: [
        "cd8b7a3b5a44280e78c184557b85fd90d58f76cccb57414f1601d1673be5b475:1616058267892",
        "2cdd97a2e8802c511bb5eef7c41e4db83477b53437cd3e60dd20846d04b3cdd2:1616058256139",
        "5ae1b6205369df7104c76955439e0cd002ad16a18346af5396b437a0b2849fb4:1616057586160",
        "92f27682a8500f8e2161fbc511d54df88a2d3aeb26d32e5bb1dd4370a124835e:1616057527444",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "rpe",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1616053795675,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1616054151941,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1616054465475,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1616054798519,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1616055124446,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1616055472937,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "7",
          timestamp: 1616055810756,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "8",
          timestamp: 1616056186405,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "9",
          timestamp: 1616056553271,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "10",
          timestamp: 1616056901099,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "11",
          timestamp: 1616057240364,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1616053913015,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1616053930750,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1616054101042,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1616054112755,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_pause",
          timestamp: 1616056819402,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "auto_resume",
          timestamp: 1616056836117,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1616057517823,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1616054014439,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1616054536709,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1616055066787,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "4",
          timestamp: 1616055619734,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "5",
          timestamp: 1616056202549,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "6",
          timestamp: 1616056761272,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "7",
          timestamp: 1616057331734,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "e9f33e7f-08e0-47c4-bf1b-bf1747ad7821",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1616398512188,
      end_epoch_ms: 1616400202429,
      last_modified: 1616400351406,
      active_duration_ms: 1685000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 493.601,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4595,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.043729289634109,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 52.49858332274731,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1474.3352149804869,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.567969992174303,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.895959627197325,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Monday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "4.766666666666666",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "eff1461779eee4e5d8d9ad0983fe39b34ba69224f03e56e49ee8f94117c3fa81:1616400351406",
        "35c52ec9dd42885e57136252179c24e630bce8ade45bc00ee7d091e4cdb8b3ed:1616400326119",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1616398802475,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1616399088577,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1616399392908,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1616399694624,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1616400005744,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1616398977413,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1616399457633,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1616399951718,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1616400197953,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "dc815bc1-7f32-445f-af9a-53304f2e2c11",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1616584763485,
      end_epoch_ms: 1616587779432,
      last_modified: 1616587917589,
      active_duration_ms: 3012000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 8177,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.356469353547216,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 820.887,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.201408248563242,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 9.371844901297912,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 49.75100494396506,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 2497.500448187046,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Wednesday Afternoon Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "12.111111111111109",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "fd751706f40ea8fbd4dab6ed1c1806969424b2daa964f09357ddabd27af0c18c:1616587917589",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1616585055396,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1616585351609,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1616585659589,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1616585964120,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1616586271075,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1616586584296,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "7",
          timestamp: 1616586940181,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "8",
          timestamp: 1616587312592,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "9",
          timestamp: 1616587653776,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1616585238796,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1616585723934,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1616586217065,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "4",
          timestamp: 1616586724470,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "5",
          timestamp: 1616587327497,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1616587775959,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "933a5463-8ba7-4034-9971-9365ec370c36",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1616838463839,
      end_epoch_ms: 1616843818898,
      last_modified: 1616845707280,
      active_duration_ms: 5112000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.653920481221406,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 15.069189650434277,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 10.612105387629773,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 13825,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 256.242,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 4028.342309846746,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 47.28101302637026,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Saturday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "5.9611111111111095",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "dffe57318deab50a4d20a6ad60a23c2a8e73bfca2f990b524b9175eabcdece52:1616845707280",
        "a7dc254a1340afe2e0519eeefa9da5b6831fafcf12ed0c665784c0d2edbccaa2:1616844109764",
        "dad07717e3f40cf86e9e0499123396f2735ee23e7dd6f50f2ff1de149a6906a0:1616843922640",
        "5bcb6bfe1144c8a8c2bba5613b8ee0ae5564340c318da879804e2c1d9798643e:1616843838813",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1616838751588,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1616839053200,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1616839648439,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1616839969581,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1616840296676,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1616840677135,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "7",
          timestamp: 1616841025037,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "8",
          timestamp: 1616841360234,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "9",
          timestamp: 1616841743509,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "10",
          timestamp: 1616842072768,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "11",
          timestamp: 1616842463206,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "12",
          timestamp: 1616842784226,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "13",
          timestamp: 1616843114062,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "14",
          timestamp: 1616843455372,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "15",
          timestamp: 1616843786162,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1616838934954,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1616839719523,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1616840240753,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "4",
          timestamp: 1616840830060,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "5",
          timestamp: 1616841374977,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "6",
          timestamp: 1616841960332,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "7",
          timestamp: 1616842550539,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "8",
          timestamp: 1616843071119,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "9",
          timestamp: 1616843620373,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1616839366093,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "resume",
          timestamp: 1616839605250,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1616843815190,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "27d20020-c914-4fbe-8298-7be0ef461d9a",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1617085762730,
      end_epoch_ms: 1617089674347,
      last_modified: 1617093200746,
      active_duration_ms: 3870000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1461.700026974211,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.570995369205368,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5068,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.182321273679412,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 370.867,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 22.66201592208079,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.577823301834858,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Tuesday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "5.833333333333333",
        "com.nike.weather": "sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "98b257c656a2caddbb7af60120eef1a3590b98cbcd383a4ee681f5b667f47a95:1617093200746",
        "7bd86b49d76292b45136106fc69e1c24874fd627895f75782e33ad064d046fe9:1617089676811",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1617086066491,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1617086397795,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1617086751161,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1617087089110,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1617088211899,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1617086267093,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1617086819532,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1617088157661,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "gps_signal",
          value: "lost",
          timestamp: 1617086796124,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "gps_signal",
          value: "found",
          timestamp: 1617086811829,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1617087947596,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "resume",
          timestamp: 1617087955451,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1617089640966,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "3a1b457c-2932-4fee-844f-d18f95e88aca",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1617374243695,
      end_epoch_ms: 1617382401695,
      last_modified: 1617382248881,
      active_duration_ms: 8158000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 21,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 6.474603174603175,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 9.26697720029419,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 40.38700079260152,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 5491.285874434054,
        },
      ],
      sources: [
        "com.nike.running.android.fullpower",
        "com.nike.running.manualentry",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.edited": "1617382246235",
        "com.nike.name": "Friday Afternoon Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "9.21111111111111",
        "com.nike.weather": "partly_sunny",
        "evaluation.run.disqualified": "true",
        "evaluation.run.version": "2.0.0",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "e7b7d8643a3168796b165a83e4a8dbd38354d25ae6f485a46735a99eb74e82f0:1617382248881",
        "f93eab8737e050d0290eb7ba40fb6d25a4a873003fa9cc76acd23a32ce0c4bc3:1617382247472",
      ],
      metric_types: [
        "distance",
        "latitude",
        "pace",
        "nikefuel",
        "speed",
        "longitude",
      ],
      metrics: [],
      moments: [],
    },
    {
      id: "a4ce58ab-3ecb-41db-bbd3-69e64b585233",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1617689775327,
      end_epoch_ms: 1617692680279,
      last_modified: 1617952796899,
      active_duration_ms: 2837000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 2029.4575483315605,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 7.6,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 6.2214912280701755,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 42.92120299608517,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 9.643990130419457,
        },
      ],
      sources: [
        "com.nike.running.android.fullpower",
        "com.nike.running.manualentry",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.edited": "1617952796445",
        "com.nike.name": "Tuesday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "0.9388888888888877",
        "com.nike.weather": "partly_sunny",
        "evaluation.run.disqualified": "true",
        "evaluation.run.version": "2.0.0",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "07287fd71bb98e5578aa594e2cbafa92002842b9a6cb24b197cde39ece73620b:1617952796899",
        "1f2d47300123e25150e22d8c67beff0dae6f3b5908d5fcb143fbcb2d9a9b97b3:1617952796152",
      ],
      metric_types: [
        "distance",
        "latitude",
        "pace",
        "nikefuel",
        "speed",
        "longitude",
      ],
      metrics: [],
      moments: [],
    },
    {
      id: "88cc47dc-a526-4120-bfa3-991299b6915a",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1617776968510,
      end_epoch_ms: 1617778727184,
      last_modified: 1618603927933,
      active_duration_ms: 1755000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4841,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.602827764520202,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.220578113292194,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.49298002978503,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 493.375,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 49.99887474446398,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1462.4670862755715,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Wednesday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "1.5666666666666669",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "877b2d8b33ac4f174f3d972c6798cdc39f965960ebd66ff44fbdc83f382c152c:1618603927933",
        "af2e17b0c82382d51bfc69e1f88ac77abb0d9f6a18ed84ea3d353f42d7fec27f:1617778931002",
        "7e3282ad2b3aa46bd199fad66a3fc6bb3777cac0f9cd902160d1573b597ce72f:1617778857251",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1617777274472,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1617777596506,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1617777905985,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1617778215864,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1617778534186,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1617777481642,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1617777973288,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1617778481096,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1617778723677,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "29532e03-c622-4c3f-b96a-4ce2ccd09831",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1617950139765,
      end_epoch_ms: 1617952337918,
      last_modified: 1617952575679,
      active_duration_ms: 2191000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 43.99964907788372,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5904,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.8100486902413095,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 6.2850878905715355,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 528.176,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1606.720518827387,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 10.326935831153596,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Friday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "4.966666666666665",
        "com.nike.weather": "cloudy",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "84c85e5b22dc35582f583edc92b4c6539a316d5878394ffaee4cdcbf13c0d51c:1617952575679",
        "e5d7c161265f3af26f4707bb8b978fc9f065858cc994b572b1221b95072ec0b0:1617952571950",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1617950455435,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1617950788296,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1617951150667,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1617951506208,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1617951861065,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1617952221220,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1617950658139,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1617951227749,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1617951801286,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1617952331449,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "e391ef81-9243-4afb-b4d9-a20c285c6cb5",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1618052470462,
      end_epoch_ms: 1618072471283,
      last_modified: 1618124650657,
      active_duration_ms: 19992000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 11385.394357465942,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 45356,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 1656.129,
        },
        {
          metric: "rpe",
          summary: "max",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 10,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 7.6638682717063995,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 7.8289445842263525,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 42.560015135542876,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 34.16985101280295,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.running.manualentry",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Saturday Afternoon Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "7.116666666666668",
        "com.nike.weather": "cloudy",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "90fc62ffee06cfa22376751d33886d077a53320347ebc5b2e20ae0908c1a6056:1618124650657",
        "36d6b67f34cda908a27c880b9733d21411457bc796f4797b7bf898ff15142555:1618072938170",
        "47b9a8a26caeab4e6d0d931cdee70cbf61c4d5a5af480fdc7e9e8ee43f835b6d:1618072902962",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "rpe",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1618052846425,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1618053304134,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1618053690957,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1618054113208,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1618054532039,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1618054928087,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "7",
          timestamp: 1618055314424,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "8",
          timestamp: 1618055730529,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "9",
          timestamp: 1618056284306,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "10",
          timestamp: 1618056735565,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "11",
          timestamp: 1618057132171,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "12",
          timestamp: 1618057535932,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "13",
          timestamp: 1618057921574,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "14",
          timestamp: 1618058337839,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "15",
          timestamp: 1618058742797,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "16",
          timestamp: 1618059190873,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "17",
          timestamp: 1618059627016,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "18",
          timestamp: 1618060302269,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "19",
          timestamp: 1618060699975,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "20",
          timestamp: 1618062020733,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "21",
          timestamp: 1618062518207,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "22",
          timestamp: 1618062945259,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "23",
          timestamp: 1618063417453,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "24",
          timestamp: 1618063971492,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "25",
          timestamp: 1618064466858,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "26",
          timestamp: 1618064860645,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "27",
          timestamp: 1618065326910,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "28",
          timestamp: 1618065723360,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "29",
          timestamp: 1618066235459,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "30",
          timestamp: 1618066679805,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "31",
          timestamp: 1618067094884,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "32",
          timestamp: 1618067775004,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "33",
          timestamp: 1618068231033,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "34",
          timestamp: 1618068724471,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "35",
          timestamp: 1618069241282,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "36",
          timestamp: 1618069695218,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "37",
          timestamp: 1618070236924,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "38",
          timestamp: 1618070649851,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "39",
          timestamp: 1618071077224,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "40",
          timestamp: 1618071502315,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "41",
          timestamp: 1618071894284,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "42",
          timestamp: 1618072248434,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1618053150126,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1618053772491,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1618054462601,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "4",
          timestamp: 1618055104274,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "5",
          timestamp: 1618055748015,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "6",
          timestamp: 1618056599270,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "7",
          timestamp: 1618057233740,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "8",
          timestamp: 1618057873543,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "9",
          timestamp: 1618058543250,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "10",
          timestamp: 1618059237489,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "11",
          timestamp: 1618060086896,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "12",
          timestamp: 1618060828675,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "13",
          timestamp: 1618062487292,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "14",
          timestamp: 1618063190380,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "15",
          timestamp: 1618064118322,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "16",
          timestamp: 1618064758822,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "17",
          timestamp: 1618065463320,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "18",
          timestamp: 1618066222959,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "19",
          timestamp: 1618066918695,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "20",
          timestamp: 1618067848928,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "21",
          timestamp: 1618068645557,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "22",
          timestamp: 1618069404923,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "23",
          timestamp: 1618070242607,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "24",
          timestamp: 1618070937459,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "25",
          timestamp: 1618071589742,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "26",
          timestamp: 1618072186898,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1618072325000,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "resume",
          timestamp: 1618072332000,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1618072469000,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "a9873744-fad1-4002-84ee-fb394c46a391",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1618563332880,
      end_epoch_ms: 1618564962952,
      last_modified: 1618565054802,
      active_duration_ms: 1624000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 53.355086755874765,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4519,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1444.1443481923436,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 12.52394658044625,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 503.345,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.649691457401309,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4.790822095462985,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Friday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "8.18888888888889",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "29a209fd6870bf3c0dfe2c54a2b19f8af3edcd7f873040c50f86556fb14d8805:1618565054802",
        "5b4aea11ee639e55d662615b10c6290823357938cc4c61ad95d6755509487f9b:1618565052623",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1618563604089,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1618563884524,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1618564172777,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1618564459021,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1618564771329,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1618563773807,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1618564232567,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1618564723497,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1618564956949,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "e5598ce8-75c7-4f99-a00c-1d24052965d0",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1618857162586,
      end_epoch_ms: 1618859110877,
      last_modified: 1618859206519,
      active_duration_ms: 1946000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.789430002051798,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5229,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.602163481005698,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 44.36599496205883,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 10.363714558900572,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 475.441,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1438.9371032694412,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Monday Evening Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "13.144444444444442",
        "com.nike.weather": "partly_cloudy_night",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "5f0199a4be116dcf6ddbe6fe9af4a5288089d7d48463f811b4473cf93ecce74e:1618859206519",
        "5a3c124dbaf174c55bdb99dc63004f7cfe67224d16930cef5bf613823e460954:1618859112446",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1618857493326,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1618857824064,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1618858171914,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1618858529109,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1618858877721,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1618857694076,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1618858248084,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1618858816573,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1618859108561,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "a051165a-1a7f-4ab3-a44b-5cc54eab99f1",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1619158789547,
      end_epoch_ms: 1619160666288,
      last_modified: 1619160781222,
      active_duration_ms: 1857000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 10.933764135702745,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 5.48758865248227,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.manualentry",
          app_id: "com.nike.sport.running.droid",
          value: 5.64,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 47.58507346433128,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1472.7580237210532,
        },
      ],
      sources: [
        "com.nike.running.android.fullpower",
        "com.nike.running.manualentry",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.edited": "1619160778556",
        "com.nike.name": "Friday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "2.627777777777776",
        "com.nike.weather": "partly_sunny",
        "evaluation.run.disqualified": "true",
        "evaluation.run.version": "2.0.0",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "8b978fe90f55dee1ad358c6391b960a041e3b998d221bb0406c9aaaad4d1c560:1619160781222",
        "d2e625e44397adea413f975124bb5d51e80bc689f905ba332bcfb2a0cdb22d3c:1619160780525",
      ],
      metric_types: [
        "distance",
        "latitude",
        "pace",
        "nikefuel",
        "speed",
        "longitude",
      ],
      metrics: [],
      moments: [],
    },
    {
      id: "d8119460-5b00-4825-841a-11127babfdd4",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1619340176629,
      end_epoch_ms: 1619343548345,
      last_modified: 1619343714386,
      active_duration_ms: 3364000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 9493,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.349087188497196,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 52.194504477065976,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 2926.3718843474994,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 12.145277609568938,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 1004.485,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4.9401917295597775,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Sunday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "7.511111111111114",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "0421b6ebeedc7dcba692761092eb4f69474bb9da9214f572fad7e09b24fa90bc:1619343714386",
        "a88221a1a91b2fe4c1d5920eeed8a19d605ea89f1c1408dafbb0d9223ce52ec4:1619343681271",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1619340497717,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1619340803980,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1619341111690,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1619341424260,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1619341735622,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1619342029300,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "7",
          timestamp: 1619342319714,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "8",
          timestamp: 1619342617672,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "9",
          timestamp: 1619342906743,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "10",
          timestamp: 1619343179506,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "11",
          timestamp: 1619343446735,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1619340684857,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1619341180478,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1619341686297,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "4",
          timestamp: 1619342157791,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "5",
          timestamp: 1619342632547,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "6",
          timestamp: 1619343086487,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "7",
          timestamp: 1619343519220,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1619343541159,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "47d32d9f-4d04-4dbf-b6d0-92000180342a",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1619547722775,
      end_epoch_ms: 1619550209172,
      last_modified: 1619550235256,
      active_duration_ms: 2470000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 6.806340447915492,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 572.837,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 6.0482820366816,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 9.920172312751324,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 42.751160349810775,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 6502,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1759.9227677338768,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Tuesday Evening Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "11.183333333333334",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "e6d961f237dfbdbfebbf32829b9476e455ce2d55ebadce5857097fa4dd241fc7:1619550235256",
        "694f20a817fc3481ad3fa16688ad2cf1f365d2318078503b14dd9f1ff8a3d2aa:1619550214999",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1619548078564,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1619548414671,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1619548792446,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1619549185507,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1619549533364,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1619549895038,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1619548279652,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1619548871098,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1619549469429,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "4",
          timestamp: 1619550063563,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1619550193482,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "33977e06-4ec3-4307-9806-a3b655575e9d",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1619796333930,
      end_epoch_ms: 1619796442420,
      last_modified: 1622325577791,
      active_duration_ms: 79000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 18.37127350236778,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 0.4031473907464041,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 3.265968469320698,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 79.88010751002173,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 105.17547488819527,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 236,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 36.686,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Friday Evening Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "10.88888888888889",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "3f55676ff50003bd69b0bc8a12cce1e6ab6efcd98a1aba1ab4c67a5457dac576:1622325577791",
        "9c88b2c786ba44f30a8f394b4efc54f1213c79f7b68a3646dac76727484eb4f0:1619796443571",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "halt",
          value: "pause",
          timestamp: 1619796412972,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "ad123efe-75b5-4b87-a6a4-150ca322144b",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1619981132054,
      end_epoch_ms: 1619988984305,
      last_modified: 1619989385906,
      active_duration_ms: 7846000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 10.400273984494666,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 20790,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 45.224939175706425,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 22.666819356206986,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 5913.914546209878,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 1967.295,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.76907878479466,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Sunday Evening Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "7.727777777777776",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "da3eb9298e16e52adf921e9d83f3633a689c53f047a1f2b6944d2f2f2ba225cd:1619989385906",
        "c70c18d722e11437d501ae07a2d7dc47d3c1a6996ad04d7ce37687cd30f9ce29:1619989369276",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1619981437570,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1619981745050,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1619982047391,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1619982354229,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1619982665633,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1619982977590,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "7",
          timestamp: 1619983287324,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "8",
          timestamp: 1619983588845,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "9",
          timestamp: 1619983897441,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "10",
          timestamp: 1619984221641,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "11",
          timestamp: 1619984576503,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "12",
          timestamp: 1619984887146,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "13",
          timestamp: 1619985225281,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "14",
          timestamp: 1619985565191,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "15",
          timestamp: 1619985923110,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "16",
          timestamp: 1619986284926,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "17",
          timestamp: 1619986663900,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "18",
          timestamp: 1619987036897,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "19",
          timestamp: 1619987428984,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "20",
          timestamp: 1619987848251,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "21",
          timestamp: 1619988267807,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "22",
          timestamp: 1619988699391,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1619981619652,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1619982111402,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1619982612517,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "4",
          timestamp: 1619983112489,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "5",
          timestamp: 1619983603545,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "6",
          timestamp: 1619984104553,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "7",
          timestamp: 1619984664006,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "8",
          timestamp: 1619985183593,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "9",
          timestamp: 1619985733318,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "10",
          timestamp: 1619986319712,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "11",
          timestamp: 1619986927084,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "12",
          timestamp: 1619987561799,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "13",
          timestamp: 1619988234510,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "14",
          timestamp: 1619988918970,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "gps_signal",
          value: "found",
          timestamp: 1619981790811,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1619988978864,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "cbb1869a-9d18-404c-9996-1bc0eb0dbb96",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1620196215715,
      end_epoch_ms: 1620198177649,
      last_modified: 1620200013001,
      active_duration_ms: 1957000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 41.50899550856537,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.244713545305742,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5157,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 6.21896055616995,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1353.8850701710405,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 447.009,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 9.647914544251748,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Wednesday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "6.022222222222224",
        "com.nike.weather": "partly_sunny",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "c183b04fc99e21b9b850127cddd324dab3bd56b957057ecc6520051e2fd86c9f:1620200013001",
        "1162d09f4ddb2e68ca8f6778cf295d4c703dd2a8394d58bed999cb70b530a8a8:1620199993364",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1620196588032,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1620196948738,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1620197311087,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1620197692631,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1620198078626,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1620196793640,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1620197394015,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1620198016131,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1620198173229,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "feaf74c0-fe4c-4bd0-945a-dd21f8f74526",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1620281807665,
      end_epoch_ms: 1620282105792,
      last_modified: 1620488252896,
      active_duration_ms: 295000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 57.757,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 0.6734072764907011,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 686,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 8.21785150971703,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 39.573122104458974,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 194.5678503469233,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 7.3011784076475745,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Thursday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "3.7666666666666675",
        "com.nike.weather": "cloudy",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "a623bbdd6d25448fc99826917ed73a5d4b944852608b7667c02c8ad21faa3da7:1620488252896",
        "ab17696fc40ae0221accb3351dda5439ec09d3801adfb70a2bb2704d1d90d603:1620283991686",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "halt",
          value: "pause",
          timestamp: 1620282102600,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "8faff8d9-39d0-4fdd-81d7-0fca14e2c4cf",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1621159197648,
      end_epoch_ms: 1621162053243,
      last_modified: 1645992101077,
      active_duration_ms: 2853000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 55.22716149844041,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4.645692572147828,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 12.91518951549142,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 912.075,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 2626.051529250841,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 10.23528769102695,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 7803,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Sunday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "13.661111111111113",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "8b5c4b8b6356325ed273e68936a2f0ca8df4b8bc5b8c4c76da7ad9b4349fe5db:1645992101077",
        "6e875b5887237ab20a91ca1313ed3a006d4dcbcf2fa7fd318ecbc5c925a8afdd:1622929250098",
        "ba8aaed450bdc06b1739f8edcf20add9e43e4152f9faaa0475c8ffb4d22073b3:1621181638693",
        "d8401348aceb3389596484790010e8f4d3de740f4c487a753975d073a8d47a22:1621181593192",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1621159462314,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1621159714260,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1621159986917,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1621160263472,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1621160551416,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1621160847534,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "7",
          timestamp: 1621161142703,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "8",
          timestamp: 1621161441434,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "9",
          timestamp: 1621161725044,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "10",
          timestamp: 1621161985321,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1621159613702,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1621160046139,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1621160502498,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "4",
          timestamp: 1621160972815,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "5",
          timestamp: 1621161455792,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "6",
          timestamp: 1621161900367,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1621162050643,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "78a5bd2f-ee93-4224-b092-b8c74b822d0d",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1621674644191,
      end_epoch_ms: 1621678871155,
      last_modified: 1621679974072,
      active_duration_ms: 4222000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 11.511822902740843,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 9.815860362356,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 6.112556391908453,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 10883,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 999.922,
        },
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 43.451746896308734,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 3057.5545899369245,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Saturday Morning Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "8.916666666666664",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "bbd37dd6645cba7c1f95cfd125a8fb6f33d57c1af3301a797e736b8a7b130b02:1621679974072",
        "092ae30c9c6a2c5dc97d19a3ba13b6a0c46cf04e76bf4cac204b84cef80f7819:1621679897453",
        "8730767d23a28273cb438ebeb89351e300f14f9666a7a2c15513783658b6458e:1621679032801",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1621674980410,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1621675327036,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1621675674862,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1621676077890,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1621676439305,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "6",
          timestamp: 1621676821116,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "7",
          timestamp: 1621677177052,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "8",
          timestamp: 1621677558576,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "9",
          timestamp: 1621677934157,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "10",
          timestamp: 1621678320679,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "11",
          timestamp: 1621678685723,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1621675207085,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1621675758715,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1621676377879,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "4",
          timestamp: 1621676979843,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "5",
          timestamp: 1621677575637,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "6",
          timestamp: 1621678202507,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "7",
          timestamp: 1621678780771,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1621678866627,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
    {
      id: "698b8b4b-e184-4742-970b-dbb227505ab3",
      type: "run",
      app_id: "com.nike.sport.running.droid",
      start_epoch_ms: 1622054280007,
      end_epoch_ms: 1622058554309,
      last_modified: 1622186318939,
      active_duration_ms: 1459000,
      session: false,
      delete_indicator: false,
      summaries: [
        {
          metric: "nikefuel",
          summary: "mean",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 53.21270631529625,
        },
        {
          metric: "pace",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 4.8274801595701575,
        },
        {
          metric: "distance",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 5.037134459985402,
        },
        {
          metric: "speed",
          summary: "mean",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 12.428844452328612,
        },
        {
          metric: "calories",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 113.825,
        },
        {
          metric: "nikefuel",
          summary: "total",
          source: "com.nike.nikefuelengine.1.0-A5.0",
          app_id: "com.nike.sport.running.droid",
          value: 1293.9556419002872,
        },
        {
          metric: "steps",
          summary: "total",
          source: "com.nike.running.android.fullpower",
          app_id: "com.nike.sport.running.droid",
          value: 3983,
        },
      ],
      sources: [
        "com.nike.pacecalculator.v1",
        "com.nike.running.android.fullpower",
        "com.nike.nikefuelengine.1.0-A5.0",
      ],
      tags: {
        "com.nike.name": "Wednesday Evening Run",
        "com.nike.running.goaltype": "basic",
        "com.nike.temperature": "10.527777777777779",
        location: "outdoors",
        shoe_id: "6fb9ea2d-6320-4779-ab99-ffd6d41faa83",
      },
      change_tokens: [
        "ff0544ad0419a2d63f73fa5cdc97d8a9cf14337dab5a7609638f77fbb49d11b2:1622186318939",
        "18c5671e6205b44931e15e60647cd05b5ca1d4dea6d17ef78f232c13e3e2171b:1622058714827",
        "f2aafc41288f99af8ee0702edcf9d60040724d25723d2e925ad9d2544269a446:1622058629539",
      ],
      metric_types: [
        "distance",
        "horizontal_accuracy",
        "pace",
        "latitude",
        "calories",
        "steps",
        "speed",
        "nikefuel",
        "longitude",
      ],
      metrics: [],
      moments: [
        {
          key: "split_km",
          value: "1",
          timestamp: 1622054565967,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "2",
          timestamp: 1622057690825,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "3",
          timestamp: 1622057983961,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "4",
          timestamp: 1622058271163,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_km",
          value: "5",
          timestamp: 1622058538614,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1622054655711,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "resume",
          timestamp: 1622057470592,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "halt",
          value: "pause",
          timestamp: 1622058554276,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "1",
          timestamp: 1622057571348,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "2",
          timestamp: 1622058048341,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
        {
          key: "split_mile",
          value: "3",
          timestamp: 1622058492365,
          app_id: "com.nike.sport.running.droid",
          source: "com.nike.running.android.fullpower",
        },
      ],
    },
  ],
  paging: {
    after_time: 1622183776690,
    after_id: "698b8b4b-e184-4742-970b-dbb227505ab3",
  },
};
